
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































.process-steps {
  position: relative;
  border-top: 1px solid $c-light-grey;

  @include mq(s) {
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      width: 40%;
      height: 100%;
      background: $c-mustard;
      transform: none;
    }
  }
}

.process-nav {
  // position: absolute;
  width: 100%;
  // opacity: 0;

  &.is-sticky {
    position: sticky;
    opacity: 1;
  }
}

.process-steps__list {
  @extend %list-nostyle;

  @include mq(xl) {
    padding: $spacing * 4 0;
  }
}

.process-steps__item {
  & + & {
    @include default-margin();
  }

  @include mq(xxl) {
    & + & {
      margin-top: $spacing * 4;
    }
  }
}

@include mq(s) {
  .process-steps__wrapper {
    position: relative;
  }

  .process-steps__list {
    position: sticky;
    top: 0;
    padding-right: col(5.5, 10);
  }
}
