
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































.title {
  margin: $spacing 0;
}

::v-deep {
  .picture__container {
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }

  .picture__caption {
    display: none;
  }
}

@include mq(s) {
  .process-steps__item__picture {
    @include fluid(
      bottom,
      (
        xxs: 60px,
        m: 80px,
        xl: 100px,
      )
    );

    position: absolute;
    top: 0;
    right: 0;
    width: col(4.5, 10);
    height: 100%;
    margin: 0;

    .visible & {
      z-index: 3;
    }

    .previous & {
      z-index: 2;
    }

    .picture {
      @include fluid(
        top,
        (
          xxs: 60px,
          m: 80px,
          xl: 100px,
        )
      );

      position: sticky;

      .process & {
        top: 15rem;
      }
    }

    ::v-deep {
      .picture__container {
        height: 60vh;
        max-height: 80rem;
        padding-bottom: 0;

        .prices & {
          max-height: 70rem;
        }
      }

      img {
        transform: scale(1.05);
      }
    }
  }

  .current {
    .process-steps__item__picture {
      z-index: 3;
    }

    ::v-deep {
      img {
        transform: scale(1);
      }
    }
  }

  @include mq(l) {
    .process-steps__item__picture ::v-deep {
      .picture__container {
        height: 75vh;
      }
    }
  }
}
