
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.process__flexible {
  ::v-deep > div:first-child {
    margin-top: 0;
  }
}

.process__video {
  // compensate sticky nav height
  @include mq($until: l) {
    margin-top: $spacing * -2;
  }
}
